// VIU News and Events on homepage

// Title styles TBD after actual implementation

// Change grid to 12
// Set cols for nested grid calcs


.news_events_title{
  font-size:2em;
}

.viuEvents__event,
.viuNews__story{
  margin-bottom:2em;
}

.viuNews__story__heading, .viuEvents__event__heading{
  font-size: 1em;
  margin-top: 0;
  @include breakpoint('sm'){
    font-size:19px;
  }
  @include breakpoint('md'){
    font-size:22px;
  }
  a{
    text-decoration: none !important;
  }
}

#viuNewsEvents {
  .viuNews__story__heading,
  .viuEvents__event__heading {
    margin: 0 0 .5em 0;
    a {
      color: white !important;
    }
  }
}

.viuNews__story__photo,
.viuEvents__event__dateicon{
  float:right;
  margin-left: 10px;
}

.viuEvents__event__dateicon{
  position: relative;
  background:transparent;
  border:0;
  height:60px;
  width:60px;
  padding:5px 0;
  border:3px solid;

  .oa-event-date-month-wrapper{
    background:transparent;
    margin:0;
    line-height: 1em;
  }

  .oa-event-date-month{
    color:inherit;
  }

  .oa-event-date-day-wrapper,
  .oa-event-date-day{
    font-size:22px;
    font-weight:bold;
    line-height:0.9em;
  }
}
.page-section--text-only.page-section--color-1, .page-section--text-only.page-section--color-2{
  .viuEvents__event__dateicon
  {
    border-color:white;
  }
}