// Specific styles for VIU program pages
// --------------------------------------------------

@import "../../../../../../profiles/viu/themes/viu_theme/scss/base/mixins";

.program-header{
  ul{
    margin-left:0;
  }
  li{
    margin-bottom: 1em;
    @include make-sm-column(6);
    @include make-md-column(3);
  }
  .field-item{
    font-weight: bold;
    color: $brand-primary;
  }
}
.field-label:before{
  font-family: FontAwesome;
  margin-right: .5em;
}
.field-program-length .field-label:before{
  content:"\f133";
}
.field-credential .field-label:before{
  content:"\f19d";
}
.field-program-options .field-label:before{
  content:"\f14a";
}
.field-campus .field-label:before{
  content:"\f041";
}

.field-intake-next .field-label:before{
  content:"\f252";
}

.field-program-website a{
  @include cta-button;
}

.program-website-links{
  clear:both;
  .field, .cta-button{
    display: inline-block;
    vertical-align: top;
    margin-right: .5em;
  }
}

.node-program{
  .view-display-id-intake_pre_open, .view-display-id-intake_open{
    .view-content{
      margin: 1.5em 0;
      padding: 0.6em 0;
    }
  }
}


.row--apps_accepting{
  .program_node__intake_next{
    @include make-md-column(4);
    @include make-md-column-offset(4);
  }
  .program_node__accepting_apply{
    @include make-md-column(4);
    @include make-md-column-offset(-4);
    /* adjust for above mixin not actually implemented properly in twbs 3 */
    @media (min-width: $screen-md) {
      margin-left: -66.6666% ;
    }
  }
  .program_node__program_website{
    @include make-md-column(4);
  }
}
.row--apps_open{
  .program_node__applications_open{
    @include make-md-column(6);
  }
  .program_node__program_website{
    @include make-md-column(6);
  }
}

@media (min-width: $screen-md) {
  .node-program .field-intake-pre{
    float:left;
    margin-right: 2em;
  }
  .node-program .field-program-website{
    float:right;
  }
}

/* Intake tables */

.clickable{
  cursor: pointer;
}
.clickable:not(.collapsed){
  .fa-plus-square:before{
    content:"\f146";
  }
}

.viuIntake__detail {
  margin-bottom: 0.4em;
}