// Styles for individual pages
// --------------------------------------------------


////////// INTERNATIONAL HOMEPAGE /////////

//
// Homepage summary section blocks.
//
.thumbnail-style .thumbnail-img {
  position: relative;
  margin-bottom: 11px;
}
.thumbnail-kenburn img {
  left: 10px;
  margin-left: -10px;
  position: relative;
  transition: all 0.8s ease-in-out;
}
.thumbnail-kenburn:hover img {
  transform: scale(1.2) rotate(2deg);
}
.hover-effect {
  transition: all 0.4s ease-in-out;
}
.section-summary {

  // Apply styling even if .btn-more isn't an <a>.
  .thumbnail-style {
    .btn-more {
      right: -10px;
      bottom: 10px;
      color: #fff;
      padding: 1px 6px;
      position: absolute;
      background: #003F5F;
      display: inline-block;
    }
    .btn-more:hover {
      cursor: pointer;
      text-decoration: none;
      box-shadow: 0 0 0 2px #324c80;
    }
    &:hover .btn-more {
      right: 10px;
    }
  }

  // Don't underline titles on hover
  a:hover {
      text-decoration: none;
  }
}
