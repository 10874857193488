/* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 1148px;
  }
}

/* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
.container, .section-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after, .section-container:before, .section-container:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after, .section-container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1148px;
  }
}

@media (min-width: 1300px) {
  /* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1270px;
  }
}

@media (min-width: 1400px) {
  /* line 12, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1370px;
  }
}

/* line 38, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 38, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 38, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 38, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 47, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 47, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 47, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 47, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/*
 * A partial implementation of the Ruby list functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/lists.rb
 */
/*
 * A partial implementation of the Ruby constants functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/constants.rb
 */
/*
 * A partial implementation of the Ruby display functions from Compass:
 * https://github.com/Compass/compass/blob/stable/core/lib/compass/core/sass_extensions/functions/display.rb
 */
/**
 * Fix for vw, vh, vmin, vmax on iOS 7.
 * http://caniuse.com/#feat=viewport-units
 *
 * This fix works by replacing viewport units with px values on known screen sizes.
 *
 * iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
 * Target devices running iOS 8+ will incidentally execute the media query,
 * but this will still produce the expected result; so this is not a problem.
 *
 * As an example, replace:
 *
 *   height: 50vh;
 *   font-size: 5vmin;
 *
 * with:
 *
 *   @include viewport-unit(height, 50vh);
 *   @include viewport-unit(font-size, 5vmin);
 */
/* line 7, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.program-header ul {
  margin-left: 0;
}

/* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.program-header li {
  margin-bottom: 1em;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 992px) {
  /* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 25%;
  }
}

@media (min-width: 1200px) {
  /* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 15, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.program-header .field-item {
  font-weight: bold;
  color: #003E5B;
}

/* line 20, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.field-label:before {
  font-family: FontAwesome;
  margin-right: .5em;
}

/* line 24, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.field-program-length .field-label:before {
  content: "\f133";
}

/* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.field-credential .field-label:before {
  content: "\f19d";
}

/* line 30, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.field-program-options .field-label:before {
  content: "\f14a";
}

/* line 33, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.field-campus .field-label:before {
  content: "\f041";
}

/* line 37, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.field-intake-next .field-label:before {
  content: "\f252";
}

/* line 41, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.field-program-website a {
  display: table;
  margin: 1.5em 0;
  padding: 0.6em 1em;
  border-radius: 500px;
  border: 1px solid;
  line-height: 1.2em;
  text-align: center;
}

/* line 37, /home/boquistm/projects/d7/viu-international-2/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.field-program-website a:hover, .field-program-website a:focus {
  text-decoration: none;
}

/* line 45, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.program-website-links {
  clear: both;
}

/* line 47, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.program-website-links .field, .program-website-links .cta-button {
  display: inline-block;
  vertical-align: top;
  margin-right: .5em;
}

/* line 56, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.node-program .view-display-id-intake_pre_open .view-content, .node-program .view-display-id-intake_open .view-content {
  margin: 1.5em 0;
  padding: 0.6em 0;
}

/* line 65, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__intake_next {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 65, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 65, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 65, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 65, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    margin-left: 33.33333%;
  }
}

/* line 69, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__accepting_apply {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  /* adjust for above mixin not actually implemented properly in twbs 3 */
}

@media (min-width: 768px) {
  /* line 69, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 69, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    margin-left: -33.33333%;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    margin-left: -66.6666%;
  }
}

/* line 77, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__program_website {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 77, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 77, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 77, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 82, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.row--apps_open .program_node__applications_open {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 82, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 82, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  /* line 82, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 85, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.row--apps_open .program_node__program_website {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 85, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 85, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  /* line 85, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 91, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .node-program .field-intake-pre {
    float: left;
    margin-right: 2em;
  }
  /* line 95, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
  .node-program .field-program-website {
    float: right;
  }
}

/* Intake tables */
/* line 102, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.clickable {
  cursor: pointer;
}

/* line 106, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.clickable:not(.collapsed) .fa-plus-square:before {
  content: "\f146";
}

/* line 111, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/layout/_program_node.scss */
.viuIntake__detail {
  margin-bottom: 0.4em;
}

/**
 * Live result widget.

 -- Moved to viu_theme

 */
/**
 * Styles for site banner
 */
/* line 6, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_banner.scss */
.header-v4 .header-banner-title {
  text-transform: none;
}

/* line 11, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_banner.scss */
#sequence-theme {
  background: none;
}

/* line 14, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_banner.scss */
#sequence-theme #sequence {
  margin-top: -10px;
  min-height: 280px;
}

/* line 21, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_banner.scss */
.sequence-inner {
  border-bottom: 0;
}

/* line 23, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_banner.scss */
.sequence-inner ul {
  padding-left: 0;
}

/* line 31, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_banner.scss */
#sequence-theme + .clearfix + .navbar-collapse, #sequence-theme + .panel-bootstrap-pane + .navbar-collapse {
  border-bottom: 0;
}

/* line 6, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_calendar.scss */
.view-oa-event-list li {
  list-style-type: none;
}

@media (min-width: 992px) {
  /* line 4, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_flex_ctas.scss */
  .globalCTAs__cta {
    padding-right: 2em;
  }
}

/* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_flex_ctas.scss */
.pane-viu-flex-ctas .globalCTAs__cta {
  text-align: left;
}

/**
 * Styles for iframe
 */
/* line 5, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_iframe.scss */
iframe {
  width: 100%;
}

/* line 5, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_layout.scss */
#content > .container {
  padding-top: 25px;
  padding-bottom: 40px;
}

/* line 5, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_menu.scss */
.toolbar-search .btn {
  background: none;
  color: #ffffff;
}

/* line 9, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.news_events_title {
  font-size: 2em;
}

/* line 13, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.viuEvents__event,
.viuNews__story {
  margin-bottom: 2em;
}

/* line 18, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.viuNews__story__heading, .viuEvents__event__heading {
  font-size: 1em;
  margin-top: 0;
}

@media (min-width: 768px) {
  /* line 18, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
  .viuNews__story__heading, .viuEvents__event__heading {
    font-size: 19px;
  }
}

@media (min-width: 992px) {
  /* line 18, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
  .viuNews__story__heading, .viuEvents__event__heading {
    font-size: 22px;
  }
}

/* line 27, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.viuNews__story__heading a, .viuEvents__event__heading a {
  text-decoration: none !important;
}

/* line 33, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
#viuNewsEvents .viuNews__story__heading,
#viuNewsEvents .viuEvents__event__heading {
  margin: 0 0 .5em 0;
}

/* line 36, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
#viuNewsEvents .viuNews__story__heading a,
#viuNewsEvents .viuEvents__event__heading a {
  color: white !important;
}

/* line 42, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.viuNews__story__photo,
.viuEvents__event__dateicon {
  float: right;
  margin-left: 10px;
}

/* line 48, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon {
  position: relative;
  background: transparent;
  border: 0;
  height: 60px;
  width: 60px;
  padding: 5px 0;
  border: 3px solid;
}

/* line 57, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-month-wrapper {
  background: transparent;
  margin: 0;
  line-height: 1em;
}

/* line 63, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-month {
  color: inherit;
}

/* line 67, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-day-wrapper,
.viuEvents__event__dateicon .oa-event-date-day {
  font-size: 22px;
  font-weight: bold;
  line-height: 0.9em;
}

/* line 75, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_news_events.scss */
.page-section--text-only.page-section--color-1 .viuEvents__event__dateicon, .page-section--text-only.page-section--color-2 .viuEvents__event__dateicon {
  border-color: white;
}

/* line 10, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_page.scss */
.thumbnail-style .thumbnail-img {
  position: relative;
  margin-bottom: 11px;
}

/* line 14, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_page.scss */
.thumbnail-kenburn img {
  left: 10px;
  margin-left: -10px;
  position: relative;
  transition: all 0.8s ease-in-out;
}

/* line 20, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_page.scss */
.thumbnail-kenburn:hover img {
  transform: scale(1.2) rotate(2deg);
}

/* line 23, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_page.scss */
.hover-effect {
  transition: all 0.4s ease-in-out;
}

/* line 30, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_page.scss */
.section-summary .thumbnail-style .btn-more {
  right: -10px;
  bottom: 10px;
  color: #fff;
  padding: 1px 6px;
  position: absolute;
  background: #003F5F;
  display: inline-block;
}

/* line 39, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_page.scss */
.section-summary .thumbnail-style .btn-more:hover {
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 0 0 2px #324c80;
}

/* line 44, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_page.scss */
.section-summary .thumbnail-style:hover .btn-more {
  right: 10px;
}

/* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_page.scss */
.section-summary a:hover {
  text-decoration: none;
}

/* line 6, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_panel.scss */
.p-title-link:hover {
  text-decoration: none;
  color: #2b6b84;
}

/* line 17, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_panel.scss */
.section-summary .thumbnails {
  padding: 12px;
}

/* line 20, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_panel.scss */
.section-summary .thumbnail-style {
  margin-bottom: 0;
}

/* line 23, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_panel.scss */
.section-summary .list-group {
  margin: 0 -20px 0 -20px;
}

/* line 31, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_panel.scss */
.section-summary .list-group-item {
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  line-height: 1.3;
  transition: all 0.3s ease-in-out;
}

/* line 44, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_panel.scss */
.section-summary .list-group-item > span:before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: .75em;
  margin-right: .5em;
  margin-left: -10px;
}

/* line 1, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_paragraphs.scss */
.oa-related-paragraph-form .text-tabname {
  max-width: 300px;
  margin-bottom: 15px;
}

/* line 9, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.section-scholarship #content .view-section-content .views-field-title {
  background-color: #F5841F;
  border: 2px solid #F37021;
}

/* line 22, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.section-teaching-resources #content .view-section-content .views-field-title {
  background-color: #4C96B5;
  border: 2px solid #005C7E;
}

/* line 31, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-about-us-pages {
  background: #4C96B5;
  text-align: center;
  margin-bottom: 2em;
}

/* line 35, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-about-us-pages .btn-u {
  padding-top: .75em;
  padding-bottom: .75em;
}

/* line 38, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-about-us-pages .btn-u:before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: .75em;
  margin-right: .5em;
}

@media (min-width: 768px) {
  /* line 46, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-about-us-pages .view-content {
    display: flex;
    justify-content: space-around;
  }
  /* line 50, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-about-us-pages .btn-u {
    flex-basis: grow;
    flex-grow: 1;
    text-align: center;
  }
}

/* line 60, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-topic-boxes .service {
  min-height: 150px;
}

/* line 63, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-topic-boxes .desc p {
  line-height: 1.2;
}

@media (min-width: 768px) and (max-width: 1199px) {
  /* line 68, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-topic-boxes .service-alternative {
    padding: 0;
  }
  /* line 71, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-topic-boxes .service-alternative .service i.fa {
    padding: 10px 0;
  }
  /* line 74, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-topic-boxes .service-alternative .service h4 {
    font-size: 18px;
    line-height: 1.4em;
  }
}

@media (min-width: 768px) {
  /* line 84, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-topic-boxes .row {
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }
  /* line 87, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-topic-boxes .row .col {
    float: none;
    display: -webkit-flex;
    display: flex;
  }
  /* line 90, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-topic-boxes .row .col > a {
    flex-grow: 1;
    display: -webkit-flex;
    display: flex;
  }
  /* line 93, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
  .view-topic-boxes .row .col > a .service {
    flex-grow: 1;
  }
}

/* line 106, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-groups-feature-list .views-bootstrap-grid-plugin-style > .row > .col-sm-4:first-child {
  padding-right: 7px;
}

/* line 109, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-groups-feature-list .views-bootstrap-grid-plugin-style > .row > .col-sm-4:last-child {
  padding-left: 7px;
}

/* line 112, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-groups-feature-list .views-bootstrap-grid-plugin-style > .row > .col-sm-4:not(:first-child):not(:last-child) {
  padding-right: 7px;
  padding-left: 7px;
}

/* line 126, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-display-id-oa_events_upcoming_small .views-row {
  display: table;
}

/* line 129, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-display-id-oa_events_upcoming_small .views-field-field-oa-date, .view-display-id-oa_events_upcoming_small .event-info-group {
  display: table-cell;
  vertical-align: middle;
}

/* line 133, /home/boquistm/projects/d7/viu-international-2/sites/all/themes/viu_international_theme/scss/components/_view.scss */
.view-display-id-oa_events_upcoming_small .oa-event-date-wrapper {
  float: none;
}
