/**
 * Styles for site banner
 */

// LM-73 change case of main heading
.header-v4 .header-banner-title {
  text-transform: none;
}

// Sequence plugin
#sequence-theme {
  background: none;

  #sequence {
    margin-top: -10px;
    min-height: 280px;
  }

}

.sequence-inner{
  border-bottom: 0; //LM-86
  ul  {
    padding-left: 0;
  }
}


//CW-5 : remove grey border below slideshow

#sequence-theme + .clearfix + .navbar-collapse{
  border-bottom:0;
}


