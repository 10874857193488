// Styles for panels and panes.
// --------------------------------------------------

// Class for links in pane titles (started with mini calendar link)
.p-title-link{
  &:hover{
    text-decoration: none;
    color: #2b6b84;
  }
}

.panel-bootstrap-pane{
  @extend .clearfix;
}

.section-summary {
	.thumbnails {
		padding: 12px;
	}
  .thumbnail-style {
    margin-bottom: 0;
  }
	.list-group {
      margin: 0 -20px 0 -20px;
    }
    a, li {
      // color: #ffffff;
    }


    .list-group-item {
      border: none;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 30px;
      line-height: 1.3;
      transition: all 0.3s ease-in-out;
      // background-color: blue;

      &:hover {
        // background-color: red;
      };

        & > span:before {
          content: "\f0da";
          font-family: FontAwesome;
          font-size: .75em;
          margin-right: .5em;
          margin-left:-10px;
        }
    }
}



